import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'Home',
    component: () => import(/* webpackChunkName: "layout" */ '@/layout/'),
    children: [
      {
        path: '/',
        component: () => import(/* webpackChunkName: "index" */ '../views/index.vue'),
        meta: { title: '首页' }
      },
      {
        path: 'question',
        component: () => import(/* webpackChunkName: "question" */ '../views/answerQuestion.vue'),
        meta: { title: '热点答疑' }
      },
      {
        path: 'quesDetail/:id',
        props: true,
        component: () => import(/* webpackChunkName: "quesDetail" */ '../views/questionDetail.vue'),
        meta: { title: '热点答疑详情', activeMenu: '/question' }
      },
      {
        path: 'ratePayCourse',
        component: () => import(/* webpackChunkName: "ratePayCourse" */ '../views/ratePayCourse.vue'),
        meta: { title: '纳税课堂' }
      },
      {
        path: 'ratePayCourseDetail/:id',
        props: true,
        component: () => import('../views/ratePayCourseDetail.vue'),
        meta: { title: '纳税课堂详情', activeMenu: '/ratePayCourse' }
      },
      {
        path: 'userCenter',
        component: () => import(/* webpackChunkName: "userCenter" */ '../views/userCenter.vue'),
        meta: { title: '用户中心', authentication: true },
        redirect: 'userCenter/myReport',
        children: [
          {
            path: 'myReport',
            component: () => import('../views/userCenter/myReport.vue'),
            meta: { title: '我的报告', activeMenu: '/userCenter' }
          },
          {
            path: 'userQuestion',
            component: () => import('../views/userCenter/userQuestion.vue'),
            meta: { title: '用户答疑', activeMenu: '/userCenter' }
          },
          {
            path: 'personInfo',
            component: () => import('../views/userCenter/personInfo.vue'),
            meta: { title: '个人信息', activeMenu: '/userCenter' }
          },
          {
            path: 'userList',
            component: () => import('../views/userCenter/userList.vue'),
            meta: { title: '用户列表', activeMenu: '/userCenter' }
          }
        ]
      },
      {
        path: 'policyRetrieve',
        component: () => import('../views/policyRetrieve.vue'),
        meta: { title: '政策检索' }
      },
      {
        path: 'policyDetail/:id',
        props: true,
        component: () => import('../views/policyDetail.vue'),
        meta: { title: '政策检索详情', activeMenu: '/policyRetrieve' }
      },
      {
        path: 'onlineMeasure',
        component: () => import('../views/onlineMeasure.vue'),
        meta: { title: '在线评估' }
      },
      {
        path: 'offlineMeasure',
        component: () => import(/* webpackChunkName: "policyRetrieve" */ '../views/offlineMeasure.vue'),
        meta: { title: '离线评估' }
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/userCenter.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // console.log(to, from, store)
  const { code, state } = to.query
  if (code && state) {
    store.dispatch('queryUserInfo', {
      code,
      state
    }).then(res => {
      next({
        path: to.path,
        replace: true
      })
    })
  } else if (to.meta.authentication) {
    if (store.state.userInfo) {
      // console.log(store.state.userInfo)
      next()
    } else {
      store.commit('SET_SHOW_LOGIN', true)
      next({ ...from, replace: false })
    }
    next()
  } else {
    next()
  }
})
export default router

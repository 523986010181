import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
import { Message } from 'element-ui'
// import { getToken, removeToken } from '@/utils/auth'
import { doLogin, logout } from '@/api/getData'
Vue.use(Vuex)
// 可以使用secure-ls对存储的数据进行加密，压缩等
const ls = new SecureLS({ isCompression: false })

export default new Vuex.Store({
  state: {
    showLogin: false, // 是否显示登录弹窗
    userInfo: null,
    token: null,
    roles: ['admin']
  },
  mutations: {
    SET_SHOW_LOGIN (state, value) {
      state.showLogin = value
    },
    SET_USER_INFO (state, userInfo) {
      state.userInfo = userInfo
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    }
  },
  actions: {
    queryUserInfo ({ commit, state }, paylod) {
      return new Promise((resolve, reject) => {
        if (state.userInfo && state.token) {
          resolve(state.userInfo)
        } else {
          paylod.referer = window.location.href.split('?')[0]
          doLogin(paylod).then(({ code, data }) => {
            commit('SET_USER_INFO', data)
            commit('SET_TOKEN', data.jwt)
            commit('SET_SHOW_LOGIN', false)
            Message({
              message: `欢迎您,${data.nickname}进入税易答`,
              type: 'success'
            })
            resolve(data)
          }).then((e) => {
            reject(e)
          })
        }
      })
    },
    // remove token
    resetToken ({ commit }) {
      return new Promise(resolve => {
        logout().then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_USER_INFO', null)
          ls.removeAll()
          // removeToken()
          resolve()
        })
      })
    }
  },
  modules: {
  },
  plugins: [
    /**
     * 1.默认持久化所有state
     * 2.默认存储到localStorage
     */
    createPersistedState({
      key: 'vuex', // 存储持久状态的键。（默认：vuex）
      // storage: window.sessionStorage,
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key)
      },
      // reducer指定需要持久化的state,配置如下
      // reducer方法用来缓存state下指定数据，默认存储全部state属性（如果path和reducer同时存在则使用reducer, 忽悠paths属性）
      reducer (store) {
        return {
          // 只储存state中的user.token
          userInfo: store.userInfo,
          token: store.token
        }
      }
    })
  ]
})

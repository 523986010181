import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import 'normalize.css/normalize.css'
import 'element-ui/lib/theme-chalk/index.css'
import * as Api from '@/api/getData'
import moment from 'moment'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.config.productionTip = false
Vue.prototype.API = Api
Vue.prototype.moment = moment
Vue.prototype.downLoadBlob = (blob, fileName, options = { type: 'application/pdf' }) => {
  const linkBlob = new Blob([blob], options)
  const elink = document.createElement('a')
  elink.download = fileName
  elink.style.display = 'none'
  elink.href = URL.createObjectURL(linkBlob)
  // document.body.appendChild(elink)
  elink.click()
  URL.revokeObjectURL(elink.href) // 释放URL 对象
  // document.body.removeChild(elink)
}

Vue.prototype.downLoadUrl = (url) => {
  const downloadElement = document.createElement('a')
  downloadElement.setAttribute('target', '_blank')
  // const href = window.URL.createObjectURL(url) // 创建下载的链接
  // const href = url
  downloadElement.href = url
  downloadElement.download = decodeURI(new Date().getTime()) // 下载后文件名
  document.body.appendChild(downloadElement)
  downloadElement.click() // 点击下载
  document.body.removeChild(downloadElement) // 下载完成移除元素
  // window.URL.revokeObjectURL(url) // 释放掉blob对象
}
Vue.use(Element)
Vue.use(Viewer)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

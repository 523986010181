import request from '@/utils/request'
import store from '@/store'
/** 政策检索 */
export function getPolicySearchPage (data) {
  return request({
    url: '/sysArticle/policySearchPage',
    method: 'post',
    data
  })
}
/** 政策检索详情 */
export function getArticledetail (data) {
  return request({
    url: `/sysArticle/get/${data.id}`,
    method: 'post'
  })
}

export function getTaxCategory (data) {
  return request({
    url: '/tax/taxCategory',
    method: 'post',
    data
  })
}

export function getInfo (token) {
  return request({
    url: '/vue-element-admin/user/info',
    method: 'get',
    params: { token }
  })
}

/** 纳税课堂 */
export function getClassSearchPage (data) {
  return request({
    url: '/sysArticle/classSearchPage',
    method: 'post',
    data
  })
}

/** 热点答疑 */
export function getHotQuestion (data) {
  return request({
    url: '/question/hotQuestion',
    method: 'post',
    data
  })
}

/** 热点答疑-问题类型 */
export function getCategory () {
  return request({
    url: '/tax/listCategory',
    method: 'post',
    data: { code: 'RDDY' }
  })
}

/** 热点答疑 */
export function getHotDetail (data) {
  return request({
    url: `/question/get/${data.id}`,
    method: 'post'
  })
}

/** 用户提问 */
export function questionPush (data) {
  return request({
    url: '/userQuestion/save',
    method: 'post',
    data
  })
}

/** 用户答疑 */
export function listByPage (data) {
  return request({
    url: '/userQuestion/listByPage',
    method: 'post',
    data
  })
}

/** 个人信息 */
export function geBaseInfo () {
  return request({
    url: '/wechatuserinfo/myInfo',
    method: 'get'
  })
}

/** 个人信息 */
export function editBaseInfo (data) {
  return request({
    url: '/wechatuserinfo/edit',
    method: 'post',
    data
  })
}

/** 个人信息 */
export function getUserinfo (data) {
  return request({
    url: '/wechatuserinfo/index',
    method: 'post',
    data
  })
}

/** 微信code登录 */
export function doLogin (data) {
  return request({
    url: '/wechat/doLogin',
    method: 'post',
    data
  })
}

export function tempLogin (data) {
  return request({
    url: '/wechat/login/' + `${data.unionid}`,
    method: 'post',
    data
  })
}

/** 我的报告 */
export function searchReport (data) {
  return request({
    url: '/report/listByPage',
    method: 'post',
    data
  })
}

/** 查询报告模板 */
export function queryTemplateReport () {
  return request({
    url: '/api/xsb/tax/item',
    method: 'get'
  })
}

/** 在线报告 */
export function postReport (data) {
  return request({
    url: `/api/xsb/tax/onLineImport/${data.id}`,
    method: 'post',
    data
  })
}

/** 离线报告 */
export function postOffLineReport (data) {
  return request({
    url: `/api/xsb/tax/import/${data.id}`,
    method: 'post',
    data
  })
}

/** 退出登录 */
export function logout () {
  return request({
    url: '/wechat/logout',
    method: 'post'
  })
}

/** 首頁行业动态-轮播图 */
export function getSwiperPicture () {
  return request({
    url: '/sysArticle/frontNews',
    method: 'post'
  })
}

/** 获取小程序分享模板 */
export function getShareTemplate () {
  return request({
    url: '/sysArticle/posterModel',
    method: 'post'
  })
}

/** 查询在线评估动态form */
export function getDynamicItem () {
  return request({
    url: '/api/xsb/tax/item',
    method: 'post'
  })
}

/** 现在评估保存数据生成pdf */
export function postFormData (data) {
  const id = store.state.userInfo?.id || 1
  return request({
    url: `/api/xsb/tax/formImport/${id}`,
    method: 'post',
    data
  })
}
